require('../../node_modules/uikit/dist/js/uikit.min.js');
import UIkit from 'uikit';

$(document).on('click', ".mobile-menu-icon", function ( event ) {
    $('.mobile-menu-icon label').removeClass('return');
    $('.mobile-menu-overlay').addClass('menu-fixed');
    $('html').css('overflow-y','hidden');

    if($(this).find('input').is(':checked')) {
        $('.mobile-menu-block').addClass('open');
        $('.mobile-menu-icon').addClass('open');
        $('body').addClass('filter-open');
    } else {
        $('.mobile-menu-block').removeClass('open');
        $('.mobile-menu-icon').removeClass('open');
        $('body').removeClass('filter-open');
    }
});

$(document).on('click', ".mobile-menu-overlay.menu-fixed", function ( event ) {
    $('.mobile-menu-overlay').removeClass('menu-fixed');
    $('html').css('overflow-y','visible');
    $('.mobile-menu-block').removeClass('open');
    $('.mobile-menu-icon').removeClass('open');

    if($('.mobile-menu-icon').find('input').is(':checked')) {
        $('.mobile-menu-icon label').addClass('return');
        $('.mobile-menu-icon input').prop('checked', false);
        $('body').removeClass('filter-open');
    } else {
        $('.mobile-menu-icon label').removeClass('return');
        $('.mobile-menu-icon input').prop('checked', true);
        $('body').removeClass('filter-open');
    }
});


$(document).on('click', ".mobile-menu-icon.open", function ( event ) {
    $('html').css('overflow-y','visible');
});

$(document).on('click', ".mobile-menu-block.open .close-menu", function ( event ) {
    $('.mobile-menu-overlay').removeClass('menu-fixed');
    $('html').css('overflow-y','visible');

    if($('.mobile-menu-icon').find('input').is(':checked')) {
        $('.mobile-menu-icon label').addClass('return');
        $('.mobile-menu-icon input').prop('checked', false);
        $('body').removeClass('filter-open');
    } else {
        $('.mobile-menu-icon label').removeClass('return');
        $('.mobile-menu-icon input').prop('checked', true);
        $('body').removeClass('filter-open');
    }
});

UIkit.scrollspy('.scroll-fade-fast', {cls:'uk-animation-fade', repeat: true, delay: 100});
UIkit.scrollspy('.scroll-fade-slow', {cls:'uk-animation-fade', repeat: true, delay: 200});
UIkit.scrollspy('.scroll-fade-1', {cls:'uk-animation-fade', repeat: true, delay: 200});
UIkit.scrollspy('.scroll-fade-2', {cls:'uk-animation-fade', repeat: true, delay: 400});
UIkit.scrollspy('.scroll-fade-3', {cls:'uk-animation-fade', repeat: true, delay: 600});
UIkit.scrollspy('.scroll-left-small', {cls:'uk-animation-slide-left-small', repeat: true, delay: 100});
UIkit.scrollspy('.scroll-right-small', {cls:'uk-animation-slide-right-small', repeat: true, delay: 100});
UIkit.scrollspy('.scroll-left-medium', {cls:'uk-animation-slide-left-medium', repeat: true, delay: 200});
UIkit.scrollspy('.scroll-right-medium', {cls:'uk-animation-slide-right-medium', repeat: true, delay: 200});
UIkit.scrollspy('.scroll-left', {cls:'uk-animation-slide-left', repeat: true, delay: 250});
UIkit.scrollspy('.scroll-right', {cls:'uk-animation-slide-right', repeat: true, delay: 250});
UIkit.scrollspy('.scroll-left-large', {cls:'uk-animation-slide-left', repeat: true, delay: 350});
UIkit.scrollspy('.scroll-right-large', {cls:'uk-animation-slide-right', repeat: true, delay: 350});

UIkit.parallax('.home-ambachtelijke-familiekeuken .ambachtelijke-familiekeuken + div', {
    /*y: '-5vh, 5vh',*/
    /*y: '-60vh, -42vh',*/
    y: '-40vh, -32vh',
    x: '35vw, 25vw',
    /*x: '5vw, 0vw',*/
    /*    x: '5vw, 0',*/
    viewport: 0.5,
    /*media: 320*/
    media: 768
});


UIkit.parallax('.home-ambachtelijke-familiekeuken .ambachtelijke-familiekeuken + div + div', {
    /*y: '-5vh, 5vh',*/
    y: '-60vh, -42vh',
    x: '15vw, 10vw',
    /*x: '5vw, 0vw',*/
    /*    x: '5vw, 0',*/
    viewport: 0.5,
    /*media: 992*/
    media: 768
});





/*UIkit.parallax('.home-ambachtelijke-familiekeuken .ambachtelijke-familiekeuken + div img', {*/
/*
UIkit.parallax('.home-ambachtelijke-familiekeuken .ambachtelijke-familiekeuken + div', {
    /!*y: '-5vh, 5vh',*!/
    y: '-20vh, -10vh',
    x: '25vw, 15vw',
/!*    x: '5vw, 0',*!/
    viewport: 0.5,
    media: 1200
});
*/

/*
UIkit.parallax('.home-ambachtelijke-familiekeuken .ambachtelijke-familiekeuken + div', {
    y: '-20vh, -10vh',
    x: '25vw, 15vw',
    viewport: 0.5,
    media: '@m',
});

*/



    if (window.innerWidth < 768) {

        UIkit.parallax('.home-header-col1 > div:nth-child(2)', {
            y: '85px, 0px',
            x: '-5vw, 20vw',
            viewport: 0.5,
            /*media: 320*/
            /*media: 768*/
            easing: 1.5,
        });

        UIkit.parallax('.home-header-col1 > div:nth-child(3)', {
            y: '-150px, -75px',
            /*x: '80vw, 20vw',*/
            x: '35vw, -15vw',
            viewport: 0.5,
            /*media: 320*/
            /*media: 768*/
            easing: 1.5,
        });

    }
    else {

        UIkit.parallax('.home-header-col1 > div:nth-child(2)', {
            y: '170px, 0px',
            x: '-5vw, 20vw',
            viewport: 0.5,
            /*media: 320*/
            media: 768,
            easing: 1.5,
        });

        UIkit.parallax('.home-header-col1 > div:nth-child(3)', {
            y: '-300px, -150px',
            /*x: '80vw, 20vw',*/
            x: '40vw, 20vw',
            viewport: 0.5,
            /*media: 320*/
            media: 768,
            easing: 1.5,
        });

        UIkit.parallax('.home-header-col1 > div:nth-child(4)', {
            y: '-30px, 50px',
            x: '0, 200px',
            viewport: 0.5,
            /*media: 320*/
            media: 768,
            easing: 1.5,
        });

    }


/*
UIkit.parallax('.home-header-col1 > div:nth-child(3)', {
    y: '-400px, -200px',
    x: '80vw, 10vw',
    viewport: 0.5,
    media: 768,
});
*/

/*

UIkit.parallax('.home-header-col1 > div:nth-child(3)', {
    y: '-300px, -200px',
    x: '80vw, 10vw',
    viewport: 0.5,
    media: 768
});


UIkit.parallax('.home-header-col1 > div:nth-child(2)', {
    y: '-70px, 0px',
    x: '-5vw, 10vw',
    viewport: 0.5,
    media: 1200
});


UIkit.parallax('.home-header-col1 > div:nth-child(3)', {
    y: '-70px, -220px',
    x: '-5vw, 25vw',
    viewport: 0.5,
    media: 1200
});

UIkit.parallax('.home-header-col1 > div:nth-child(2)', {
    y: '-70px, 70px',
    x: '-5vw, 15vw',
    viewport: 0.5,
    media: 1680
});


UIkit.parallax('.home-header-col1 > div:nth-child(3)', {
    y: '-70px, -220px',
    x: '-5vw, 25vw',
    viewport: 0.5,
    media: 1680
});


*/




UIkit.parallax('.over-ons-main-row2-col1 > div:nth-child(2)', {
    y: '-100px, 0px',
    x: '-100px, 100px',
    viewport: 0.5,
});

UIkit.parallax('.over-ons-main-row3-col2 > div:nth-child(2)', {
    y: '50px, -50px',
    x: '100px, 0px',
    viewport: 0.5,
});

UIkit.parallax('.contact-main-col2 > div:last-child', {
    y: '-225px, 50px',
    x: '-100px, 0px',
    viewport: 0.5,
});



